body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --brown-glow-drop-shadow: drop-shadow(0px 0px 2px hsla(0, 53%, 37%, 0.508)) drop-shadow(0px 0px 8px rgb(166, 43, 43));
  --yellow-glow-drop-shadow: drop-shadow(0px 0px 2px hsla(0, 53%, 37%, 0.508)) drop-shadow(0px 0px 8px yellow);
  --white-glow-drop-shadow: drop-shadow(0px 0px 2px hsla(0, 53%, 37%, 0.508)) drop-shadow(0px 0px 8px white);
}

#MainContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: crtAnimation 1.2s 0.2s both;
  animation-timing-function: cubic-bezier(0.2, -0.1, 0, 1);
  overflow: hidden;
  padding: 30px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
}

h1,
h3,
p,
span,
button,
select,
input {
  filter: var(--brown-glow-drop-shadow);
}

button:hover {
  filter: var(--white-glow-drop-shadow);
}

button,
select {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
  border: none;
  text-align: left;
}

#login-page *,
#admin-page * {
  --brown-glow-drop-shadow: none;
}

#login-page {
  text-align: center;
  padding-top: 50px;
}

.error-placeholder {
  height: 50px;
}

#admin-page {
  padding: 100px 0 100px 0;
}

#tree-view {
  margin: auto;
  width: 50%;
  padding-top: 20px;
}

.text-field-stack,
.button-stack {
  padding-bottom: 15px;
  padding-top: 15px;
}
